import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import MyLogin from "./plugins/login";
import http from "./plugins/http";

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(MyLogin);

// 将自定义的 axios 实例添加到 Vue 原型上
Vue.prototype._axios = http;
Vue.prototype._get = http.get;
Vue.prototype._post = http.post;

new Vue({
  router, // 如果使用路由
  render: (h) => h(App),
}).$mount("#app");
